import React from 'react'
import styles from './TextInput.module.css'

type AutoCapitalize =
  | 'off'
  | 'on'
  | 'name'
  | 'email'
  | 'username'
  | 'new-password'
  | 'current-password'
  | 'organization-title'
  | 'organization'
  | 'street-address'
  | 'address-line1'
  | 'address-line2'
  | 'address-line3'
  | 'address-line4'
  | 'country'
  | 'country-name'
  | 'postal-code'
  | 'cc-name'
  | 'cc-given-name'
  | 'cc-family-name'
  | 'cc-number'
  | 'cc-exp'
  | 'cc-exp-month'
  | 'cc-exp-year'
  | 'cc-csc'
  | 'cc-type'
  | 'transaction-currency'
  | 'transaction-amount'
  | 'language'
  | 'bday'
  | 'bday-day'
  | 'bday-month'
  | 'bday-year'
  | 'sex'
  | 'tel'
  | 'tel-country-code'
  | 'tel-national'
  | 'tel-area-code'
  | 'tel-local'
  | 'tel-extension'
  | 'impp'
  | 'url'
  | 'photo'

interface Props {
  id?: string
  type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url'
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  placeholder?: string
  autoComplete?: string
  autoCapitalize?: AutoCapitalize
  spellCheck?: boolean
}

function TextInput(props: Props, ref: React.Ref<HTMLInputElement>) {
  return <InnerTextInput {...props} ref={ref} />
}

function InnerTextInput(props: Props & { ref: React.Ref<HTMLInputElement> }) {
  return (
    <input
      ref={props.ref}
      className={styles.input}
      id={props.id}
      required={props.required}
      type={props.type}
      placeholder={props.placeholder}
      autoComplete={props.autoComplete}
      autoCapitalize={props.autoCapitalize}
      spellCheck={props.spellCheck}
      value={props.value}
      onChange={props.onChange}
    />
  )
}

InnerTextInput.defaultProps = {
  type: 'text' as Props['type'],
}

export default React.forwardRef(TextInput)
