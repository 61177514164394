import React, { useCallback } from 'react'
import { NumericValue } from '../redux/CSSVariables'
import Slider from '../components/Slider'

export interface Props {
  value: NumericValue
  onChange: (value: NumericValue) => void
}

function FontSizeSlider(props: Props) {
  const onChange = useCallback(
    e => {
      props.onChange({
        number: parseInt(e.target.value, 10),
        unit: props.value.unit,
      })
    },
    [props.onChange],
  )

  return (
    <Slider
      value={props.value.number}
      onChange={onChange}
      min={0}
      max={100}
      step={4}
    />
  )
}

export default FontSizeSlider
