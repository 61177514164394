import { useState, useEffect } from 'react'
import axios from 'axios'

const GOOGLE_FONTS_API_KEY = 'AIzaSyBwdHM9Aq6yB8m68PYKcJnlyFG184pEaJU'
const GOOGLE_FONTS_API_URL = `https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&fields=items(family%2Cvariants)&key=${GOOGLE_FONTS_API_KEY}`

export type Font = {
  family: string
  variants: string[]
}

// Cache fonts using memory
let cachedFonts: Font[] = []

export default function useGoogleFonts(): Font[] {
  const [fonts, setFonts] = useState(cachedFonts)
  useEffect(() => {
    if (fonts.length) {
      return
    }
    const source = axios.CancelToken.source()
    axios
      .get(GOOGLE_FONTS_API_URL, { cancelToken: source.token })
      .then(res => {
        cachedFonts = res.data.items
        setFonts(cachedFonts)
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          console.error(error)
        }
      })

    // Cancel request on unmount
    return () => source.cancel()
  }, [])

  return fonts
}
