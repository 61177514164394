import React from 'react'
import NativeList, { Props as NativeListProps } from '../components/NativeList'
import styles from './Navigation.module.css'

export type Props = Partial<Pick<NativeListProps, 'direction'>> & {
  className?: string
  'aria-label'?: string
  'aria-labelledby'?: string
  role?: 'navigation'
  children: React.ReactNode
}

/**
 * For semantic html, we use svg to indicate colors
 * https://www.sarasoueidan.com/blog/semantic-colors/
 * https://codepen.io/tigt/post/semantic-html-for-colors
 * @param props
 */
function Navigation(props: Props) {
  const ariaLabel = props['aria-label']
  const ariaLabelledBy = props['aria-labelledby']
  if (process.env.NODE_ENV !== 'production') {
    if (ariaLabel && ariaLabelledBy) {
      console.warn('aria-label and aria-labelledby should not be used together')
    }
    if (ariaLabel && /nav|navigation/i.test(ariaLabel)) {
      console.warn(`aria-label should not repeat word 'nav' or 'navigation'`)
    }
  }
  return (
    <nav
      className={props.className}
      role={props.role}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
    >
      <NativeList direction={props.direction}>{props.children}</NativeList>
    </nav>
  )
}

Navigation.defaultProps = {
  direction: 'row',
}

export default Navigation
