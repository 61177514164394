import React from 'react'
import styles from './Layout.module.css'
import cc from 'classcat'

interface LayoutProps {
  className?: string
  children: React.ReactNode
}

interface Props {
  className?: string
  children: React.ReactNode
}

function Layout(props: LayoutProps) {
  return (
    <div className={props.className ? props.className : styles.layout}>
      {props.children}
    </div>
  )
}

function Header(props: Props) {
  return (
    <header className={cc([styles.header, props.className])} style={{}}>
      {props.children}
    </header>
  )
}
function Aside(props: Props) {
  return (
    <aside className={cc([styles.aside, props.className])} style={{}}>
      {props.children}
    </aside>
  )
}
function Main(props: Props) {
  return (
    <main className={cc([styles.main, props.className])} style={{}}>
      {props.children}
    </main>
  )
}
function Footer(props: Props) {
  return (
    <footer className={cc([styles.footer, props.className])} style={{}}>
      {props.children}
    </footer>
  )
}

export { Layout, Header, Main, Aside, Footer }
