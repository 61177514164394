import React from 'react'
import cc from 'classcat'
import styles from './Button.module.css'

export interface Props {
  className?: string
  variant?: 'primary' | 'default'
  state?: 'focus' | 'active' | 'hover'
  disabled?: boolean
  type: 'button' | 'submit' | 'reset'
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  children: string
}

/**
 * Component that displays
 * @param props
 */
function Code(props: Props) {
  return (
    <button
      type={props.type}
      className={cc([
        styles.button,
        {
          [styles.buttonFocus]: props.state === 'focus',
          [styles.buttonActive]: props.state === 'active',
          [styles.buttonHover]: props.state === 'hover',
          [styles.buttonPrimary]: props.variant === 'primary',
          [styles.buttonDisabled]: props.disabled,
        },
        props.className,
      ])}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  )
}

Code.defaultProps = {
  type: 'button',
  variant: 'default',
}

export default Code
