import React, { useState, useEffect, useRef } from 'react'
import Prism from 'prismjs'
import styles from './PrismCode.module.css'
import cc from 'classcat'
import useFile from '../hooks/useFile'
const babel = import(/* webpackChunkName: "babel" */ '@babel/core/lib/transform')
const babelPluginTypescript = import(/* webpackChunkName: "babelPluginTypescript" */ '@babel/plugin-transform-typescript')
const prettier = import(/* webpackChunkName: "prettier" */ 'prettier/standalone')

Prism.hooks.add('wrap', function(env) {
  env.classes = (env.classes || []).map(function(c) {
    return styles[c]
  })
})

export type CodeFormat = 'tsx' | 'jsx'
export interface Props {
  url: string
  format: CodeFormat
}

/**
 * Component that displays code from url
 * @param props
 */
function Code(props: Props) {
  const ref = useRef(null)
  const fileContent = useFile(props.url)
  let [code, setCode] = useState(fileContent)

  // TODO: refactor
  useEffect(
    () => {
      setCode(fileContent)
    },
    [fileContent],
  )

  // TODO: display fetch failure
  if (!fileContent) return null

  const isJSX = props.format === 'jsx'

  useEffect(
    () => {
      if (!isJSX) return

      Promise.all([babel, babelPluginTypescript, prettier])
        .then(([babelLoaded, babelPluginTypescriptLoaded, prettierLoaded]) => {
          let code = fileContent
          const babeled = babelLoaded.transformSync(fileContent, {
            plugins: [[babelPluginTypescriptLoaded.default, { isTSX: true }]],
          })
          // TODO: display transform failure
          if (!babeled) return
          if (!babeled.code) return
          code = babeled.code

          const prettiedCode = prettierLoaded.format(code, {
            parser() {
              const transpiled = babelLoaded.transformSync(code, {
                ast: true,
                code: false,
                parserOpts: { plugins: ['jsx'] },
              })
              if (!transpiled) {
                return
              }
              return transpiled.ast
            },
          })
          setCode(prettiedCode)
        })
        .catch(err => {
          console.error(err)
        })
    },
    [fileContent],
  )

  useEffect(
    () => {
      const element = ref.current
      if (element) {
        Prism.highlightElement(element)
      }
    },
    [code],
  )

  const language = `language-${props.format}`

  return (
    <pre className={styles.pre}>
      <code ref={ref} className={cc([styles.code, language])}>
        {code}
      </code>
    </pre>
  )
}

export default Code
