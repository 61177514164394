import React from 'react'
import styles from './ViewportView.module.css'

type Props = {
  children: React.ReactNode
}

function ViewportView(props: Props) {
  return <div className={styles.viewport}>{props.children}</div>
}

export default ViewportView
