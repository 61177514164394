import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../redux/store'
import { State as ViewportState } from '../redux/Viewport'
import { isNumericValue } from './typeguards'
import styles from './ViewportPainter.module.css'

const PropToCSSVar: { [key: string]: string } = {
  viewPort: '--ones-viewport',
}

function ViewportPainter(props: ViewportState) {
  useEffect(
    () => {
      const key = 'viewPort'
      const variable = PropToCSSVar[key]
      const value = props[key]
      if (!variable) return

      let propertyValue: string
      if (isNumericValue(value)) {
        propertyValue = `${value.number}${value.unit}`
      } else {
        throw new Error(
          `Unknown type encounter: ${key}:${JSON.stringify(value, null, 2)}`,
        )
      }
      document.documentElement.style.setProperty(variable, propertyValue)
    },
    [props.viewPort],
  )

  useEffect(
    () => {
      const body = document.body
      const bodyClassList = body.classList
      body.addEventListener(
        'transitionend',
        () => {
          bodyClassList.remove(styles.transition)
        },
        { once: true },
      )
      bodyClassList.add(styles.transition)
      bodyClassList.toggle('dark-mode', props.colorScheme === 'dark')
    },
    [props.colorScheme],
  )

  return null
}

const mapStateToProps = (state: ReduxState) => ({ ...state.Viewport })

export default connect(mapStateToProps)(ViewportPainter)
