import React from 'react'
import { connect } from 'react-redux'
import { Actions } from '../redux/Viewport'
import ButtonGroup from '../components/ButtonGroup'
import Button from '../components/Button'
import { NumericValue } from '../redux/CSSVariables'

const mapDispatchToProps = {
  setViewport: Actions.setViewport,
}

type Props = typeof mapDispatchToProps
function ViewportResizer(props: Props) {
  // TODO: Change to radio group
  return (
    <ButtonGroup aria-label="resize">
      <Button onClick={() => props.setViewport({ number: 576, unit: 'px' })}>
        Mobile
      </Button>
      <Button onClick={() => props.setViewport({ number: 768, unit: 'px' })}>
        Tablet
      </Button>
      <Button onClick={() => props.setViewport({ number: 992, unit: 'px' })}>
        Desktop
      </Button>
      <Button onClick={() => props.setViewport({ number: 100, unit: '%' })}>
        Unset
      </Button>
    </ButtonGroup>
  )
}

export default connect(
  null,
  mapDispatchToProps,
)(ViewportResizer)
