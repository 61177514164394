import React from 'react'
import { NumericValue } from '../redux/CSSVariables'
import './ListsPage.css'
import NativeList from '../components/NativeList'

export interface Props {}

function ListsPage(props: Props) {
  return (
    <div>
      <h1>ListsPage</h1>
      <section>
        <h2>Unordered List</h2>
        <ul>
          <li>Item 1</li>
          <li>Item 2</li>
          <li>Item 3</li>
        </ul>
        <h2>Ordered List</h2>
        <ol>
          <li>Item 1</li>
          <li>Item 2</li>
          <li>Item 3</li>
        </ol>
        <h2>Native List</h2>
        <NativeList>
          <li>Item 1</li>
          <li>Item 2</li>
          <li>Item 3</li>
        </NativeList>
      </section>
    </div>
  )
}

export default ListsPage
