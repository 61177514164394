import React from 'react'

export interface Props {
  value?: string | number | string[]
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  min?: number
  max?: number
  step?: number
}

function Slider(props: Props, ref: React.Ref<HTMLInputElement>) {
  return (
    <input
      ref={ref}
      type="range"
      value={props.value}
      onChange={props.onChange}
      max={props.max}
      min={props.min}
      step={props.step}
    />
  )
}

export default React.forwardRef(Slider)
