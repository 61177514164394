import React from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../redux/store'
// import { Actions } from '../redux/Forms'
// import useDebounce from '../hooks/useDebounce';
import FormsPage from './FormsPage'
// import PrismCode from '../utils/PrismCode'
// import textInputURL from './Test.tsx?url'
// import textInputURL from '../components/TextInput.tsx?url'

// const mapStateToProps = (state: ReduxState) => ({
//   fontFamily: state.Forms.fontFamily,
// })
// const mapDispatchToProps = {
//   setH1: Actions.setH1,
// }

// type Props = ReturnType<typeof mapStateToProps>

function FormsPageContainer() {
  return (
    <>
      <FormsPage />
    </>
  )
}

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(FormsContainer)
export default FormsPageContainer
