import React from 'react'
import { TinyColor, ColorInput } from '@ctrl/tinycolor'
import styles from './Swatch.module.css'

export interface Props {
  color: ColorInput
  name: string
}

/**
 * For semantic html, we use svg to indicate colors
 * https://www.sarasoueidan.com/blog/semantic-colors/
 * https://codepen.io/tigt/post/semantic-html-for-colors
 * @param props
 */
function Swatch(props: Props) {
  const color: TinyColor = new TinyColor(props.color)
  const hexString = color.toHexString()
  const rgbString = color.toRgbString()
  const hslString = color.toHslString()

  return (
    <figure className={styles.swatchContainer}>
      <svg className={styles.swatch} aria-label={hexString}>
        <rect fill={hexString} width="100%" height="100%" />
      </svg>
      <figcaption>{props.name}</figcaption>
      <div>{hexString}</div>
      <div>{rgbString}</div>
      <div>{hslString}</div>
    </figure>
  )
}

export default Swatch
