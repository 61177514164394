import { HSL } from '@ctrl/tinycolor/interfaces'
import { ActionsUnion, createAction } from './helpers'
import { TinyColor } from '@ctrl/tinycolor'

export type Units = 'px' | 'em' | 'rem' | '%' | 'vw' | 'vh'
export type NumericValue = { number: number; unit: Units }
export type State = Readonly<{
  fontFamily: string
  color: HSL
  backgroundColor: HSL

  h1FontSize: NumericValue
  h2FontSize: NumericValue
  h3FontSize: NumericValue
  h4FontSize: NumericValue
  h5FontSize: NumericValue
  h6FontSize: NumericValue
  pFontSize: NumericValue

  gray100: HSL
  gray200: HSL
  gray300: HSL
  gray400: HSL
  gray500: HSL
  gray600: HSL
  gray700: HSL
  gray800: HSL
  gray900: HSL

  blue100: HSL
  blue200: HSL
  blue300: HSL
  blue400: HSL
  blue500: HSL
  blue600: HSL
  blue700: HSL
  blue800: HSL
  blue900: HSL
}>
export type StateKeys = keyof State

const INITIAL_STATE: State = {
  fontFamily: 'Source Sans Pro',
  color: { h: 0, s: 0, l: 14 },
  backgroundColor: { h: 0, s: 0, l: 100 },
  h1FontSize: { number: 48, unit: 'px' },
  h2FontSize: { number: 36, unit: 'px' },
  h3FontSize: { number: 28, unit: 'px' },
  h4FontSize: { number: 24, unit: 'px' },
  h5FontSize: { number: 20, unit: 'px' },
  h6FontSize: { number: 16, unit: 'px' },
  pFontSize: { number: 1, unit: 'em' },

  gray100: new TinyColor('#f8f9f9').toHsl(),
  gray200: new TinyColor('#e9ebed').toHsl(),
  gray300: new TinyColor('#d8dcde').toHsl(),
  gray400: new TinyColor('#c2c8cc').toHsl(),
  gray500: new TinyColor('#87929d').toHsl(),
  gray600: new TinyColor('#68737d').toHsl(),
  gray700: new TinyColor('#49545c').toHsl(),
  gray800: new TinyColor('#2f3941').toHsl(),
  gray900: new TinyColor('#24292e').toHsl(),

  blue100: new TinyColor('#edf7ff').toHsl(),
  blue200: new TinyColor('#cee2f2').toHsl(),
  blue300: new TinyColor('#adcce4').toHsl(),
  blue400: new TinyColor('#5293c7').toHsl(),
  blue500: new TinyColor('#337fbd').toHsl(),
  blue600: new TinyColor('#1f73b7').toHsl(),
  blue700: new TinyColor('#144a75').toHsl(),
  blue800: new TinyColor('#0f3554').toHsl(),
  blue900: new TinyColor('#091F30').toHsl(),
}

export enum ActionTypes {
  SET_CSS_VAR = 'SET_CSS_VAR',
}

export const Actions = {
  setCSSVariable: (property: StateKeys, value: string | NumericValue) =>
    createAction(ActionTypes.SET_CSS_VAR, { property, value }),
}

export type Actions = ActionsUnion<typeof Actions>

export function reducer(state = INITIAL_STATE, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SET_CSS_VAR: {
      const { property, value } = action.payload
      return { ...state, [property]: value }
    }
    default:
      return state
  }
}
