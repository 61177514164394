import * as React from 'react'
import styles from './Header.module.css'
import PrismCode from '../utils/PrismCode'
import nativeSelectURL from '../components/NativeSelect.tsx?url'

function Header() {
  return (
    <div>
      The design system that outputs to React, Vue components.
      <PrismCode url={nativeSelectURL} format="tsx" />
    </div>
  )
}

export default Header
