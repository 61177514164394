import React from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../redux/store'
import { Actions } from '../redux/CSSVariables'
import { NumericValue } from '../redux/CSSVariables'
// import useDebounce from '../hooks/useDebounce';
import TypographyPage from './TypographyPage'

const mapStateToProps = (state: ReduxState) => ({ ...state.CSSVariables })

const mapDispatchToProps = {
  setCSSVariable: Actions.setCSSVariable,
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
function TypographyPageContainer(props: Props) {
  const onFontFamilyChange = (value: string) =>
    props.setCSSVariable('fontFamily', value)
  const onH1Change = (value: NumericValue) =>
    props.setCSSVariable('h1FontSize', value)
  const onH2Change = (value: NumericValue) =>
    props.setCSSVariable('h2FontSize', value)
  const onH3Change = (value: NumericValue) =>
    props.setCSSVariable('h3FontSize', value)
  const onH4Change = (value: NumericValue) =>
    props.setCSSVariable('h4FontSize', value)
  const onH5Change = (value: NumericValue) =>
    props.setCSSVariable('h5FontSize', value)
  const onH6Change = (value: NumericValue) =>
    props.setCSSVariable('h6FontSize', value)
  const onPChange = (value: NumericValue) =>
    props.setCSSVariable('pFontSize', value)
  return (
    <TypographyPage
      {...props}
      onFontFamilyChange={onFontFamilyChange}
      onH1Change={onH1Change}
      onH2Change={onH2Change}
      onH3Change={onH3Change}
      onH4Change={onH4Change}
      onH5Change={onH5Change}
      onH6Change={onH6Change}
      onPChange={onPChange}
    />
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TypographyPageContainer)
