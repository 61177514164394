import React from 'react'
import cc from 'classcat'
import styles from './NativeList.module.css'

export interface Props {
  children: React.ReactNode
  ordered?: boolean
  direction: 'row' | 'col'
}

function NativeList(props: Props) {
  const ListContainer = props.ordered ? 'ol' : 'ul'
  return (
    <ListContainer
      className={cc({
        [styles.list]: true,
        [styles.listCol]: props.direction === 'col',
      })}
    >
      {props.children}
    </ListContainer>
  )
}

NativeList.defaultProps = {
  direction: 'col',
}

export default NativeList
