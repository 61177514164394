import React, { useState } from 'react'
import { State } from '../redux/CSSVariables'
import styles from './NavigationPage.module.css'
import Navigation from '../components/Navigation'
import NavLink from '../components/NavLink'
import classcat from 'classcat'
import NavigationBar from '../components/NavigationBar'

export type Props = Pick<
  State,
  | 'backgroundColor'
  | 'color'
  | 'gray100'
  | 'gray200'
  | 'gray300'
  | 'gray400'
  | 'gray500'
  | 'gray600'
  | 'gray700'
  | 'gray800'
  | 'gray900'
  | 'blue100'
  | 'blue200'
  | 'blue300'
  | 'blue400'
  | 'blue500'
  | 'blue600'
  | 'blue700'
  | 'blue800'
  | 'blue900'
>

type PseudoLinkProps = {
  index: number
  active: number
  onClick: (number: number) => void
  className: string
  activeClassName: string
  children: React.ReactNode
}
function PseudoLink(props: PseudoLinkProps) {
  return (
    <a
      className={classcat({
        [props.className]: true,
        [props.activeClassName]: props.active === props.index,
      })}
      onClick={e => {
        e.preventDefault()
        props.onClick(props.index)
      }}
      href="#"
    >
      {props.children}
    </a>
  )
}

function NavigationPage(props: Props) {
  const [active, setActive] = useState(1)

  return (
    <div>
      <h1>Navigation</h1>
      <section>
        <h2>BaseNav</h2>
        <Navigation>
          <NavLink
            element={PseudoLink}
            active={active}
            index={1}
            onClick={setActive}
          >
            Active
          </NavLink>
          <NavLink
            element={PseudoLink}
            active={active}
            index={2}
            onClick={setActive}
          >
            Link
          </NavLink>
          <NavLink
            element={PseudoLink}
            active={active}
            index={3}
            onClick={setActive}
          >
            Link
          </NavLink>
        </Navigation>
        <h3>Vertical Nav</h3>
        <Navigation direction="col">
          <NavLink
            element={PseudoLink}
            active={active}
            index={1}
            onClick={setActive}
          >
            Active
          </NavLink>
          <NavLink
            element={PseudoLink}
            active={active}
            index={2}
            onClick={setActive}
          >
            Link
          </NavLink>
          <NavLink
            element={PseudoLink}
            active={active}
            index={3}
            onClick={setActive}
          >
            Link
          </NavLink>
        </Navigation>
      </section>
      <section>
        <h2>Nav Bar</h2>
        <NavigationBar>
          <Navigation>
            <NavLink
              element={PseudoLink}
              active={active}
              index={1}
              onClick={setActive}
            >
              Active
            </NavLink>
            <NavLink
              element={PseudoLink}
              active={active}
              index={2}
              onClick={setActive}
            >
              Link
            </NavLink>
            <NavLink
              element={PseudoLink}
              active={active}
              index={3}
              onClick={setActive}
            >
              Link
            </NavLink>
          </Navigation>
        </NavigationBar>
      </section>
      <section>
        <h2>Breadcrumbs</h2>
        <div>work in progress</div>
      </section>
    </div>
  )
}

export default NavigationPage
