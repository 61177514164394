import { combineReducers } from 'redux'
import { createStore } from 'redux'
import * as CSSVariables from './CSSVariables'
import * as Viewport from './Viewport'

export interface ReduxState {
  readonly CSSVariables: CSSVariables.State
  readonly Viewport: Viewport.State
}

export default createStore(
  combineReducers<ReduxState>({
    CSSVariables: CSSVariables.reducer,
    Viewport: Viewport.reducer,
  }),
)
