import React, { ReactElement } from 'react'
import Button from './Button'
import cc from 'classcat'
import styles from './Button.module.css'

export interface Props {
  className?: string
  'aria-label': string
  children: Button[]
}

/**
 * Component that contains various buttons with semantic and accessibility enforced
 * @param props
 */
function ButtonGroup(props: Props) {
  return (
    <div
      className={cc([styles.buttonGroup, props.className])}
      role="group"
      aria-label={props['aria-label']}
    >
      {props.children}
    </div>
  )
}

export default ButtonGroup
