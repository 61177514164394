import React from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../redux/store'
import { Actions } from '../redux/CSSVariables'
import ListsPage from './ListsPage'

const mapStateToProps = (state: ReduxState) => ({ ...state.CSSVariables })

const mapDispatchToProps = {
  setCSSVariable: Actions.setCSSVariable,
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
function ListsPageContainer(props: Props) {
  return <ListsPage {...props} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListsPageContainer)
