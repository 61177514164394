import React from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../redux/store'
import { Actions } from '../redux/CSSVariables'
import ColorsPage from './ColorsPage'

const mapStateToProps = (state: ReduxState) => ({
  color: state.CSSVariables.color,
  backgroundColor: state.CSSVariables.backgroundColor,
  gray100: state.CSSVariables.gray100,
  gray200: state.CSSVariables.gray200,
  gray300: state.CSSVariables.gray300,
  gray400: state.CSSVariables.gray400,
  gray500: state.CSSVariables.gray500,
  gray600: state.CSSVariables.gray600,
  gray700: state.CSSVariables.gray700,
  gray800: state.CSSVariables.gray800,
  gray900: state.CSSVariables.gray900,
  blue100: state.CSSVariables.blue100,
  blue200: state.CSSVariables.blue200,
  blue300: state.CSSVariables.blue300,
  blue400: state.CSSVariables.blue400,
  blue500: state.CSSVariables.blue500,
  blue600: state.CSSVariables.blue600,
  blue700: state.CSSVariables.blue700,
  blue800: state.CSSVariables.blue800,
  blue900: state.CSSVariables.blue900,
})

const mapDispatchToProps = {
  setCSSVariable: Actions.setCSSVariable,
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
function ColorsPageContainer(props: Props) {
  return <ColorsPage {...props} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ColorsPageContainer)
