import { ActionsUnion, createAction } from './helpers'
import { NumericValue } from './CSSVariables'

export type State = Readonly<{
  viewPort: NumericValue
  colorScheme: 'light' | 'dark'
}>
export type StateKeys = keyof State

const INITIAL_STATE: State = {
  viewPort: { number: 100, unit: '%' },
  colorScheme: 'light',
}

export enum ActionTypes {
  SET_VIEWPORT = 'SET_VIEWPORT',
  SET_COLOR_SCHEME = 'SET_COLOR_SCHEME',
}

export const Actions = {
  setViewport: (value: State['viewPort']) =>
    createAction(ActionTypes.SET_VIEWPORT, value),
  setColorScheme: (value: State['colorScheme']) =>
    createAction(ActionTypes.SET_COLOR_SCHEME, value),
}

export type Actions = ActionsUnion<typeof Actions>

export function reducer(state = INITIAL_STATE, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SET_VIEWPORT: {
      const value = action.payload
      return { ...state, viewPort: value }
    }
    case ActionTypes.SET_COLOR_SCHEME: {
      const value = action.payload
      return { ...state, colorScheme: value }
    }
    default:
      return state
  }
}
