import React from 'react'
import { Layout, Header, Main, Aside, Footer } from '../components/Layout'
import ViewportView from '../utils/ViewportView'
import cc from 'classcat'
import styles from './LayoutPage.module.css'

export interface Props {}

function LayoutPage(props: Props) {
  return (
    <div>
      <h1>Layout</h1>
      <section>
        <h2>Usage</h2>
        <h3>Header-Content-Footer</h3>
        <div className={styles.layoutContainer}>
          <ViewportView>
            <Layout>
              <Header className={styles.header}>Header</Header>
              <Main className={styles.main}>Main</Main>
              <Footer className={styles.footer}>Footer</Footer>
            </Layout>
          </ViewportView>
        </div>
        <h3>Header-(Aside-Content)-Footer</h3>
        <div className={styles.layoutContainer}>
          <ViewportView>
            <Layout>
              <Header className={styles.header}>Header</Header>
              <Aside className={styles.aside}>Aside</Aside>
              <Main className={styles.main}>Main</Main>
              <Footer className={styles.footer}>Footer</Footer>
            </Layout>
          </ViewportView>
        </div>
        <h3>Header-(Content-Aside)-Footer</h3>
        <div className={styles.layoutContainer}>
          <ViewportView>
            <Layout className={styles.asideRight}>
              <Header className={styles.header}>Header</Header>
              <Main className={styles.main}>Main</Main>
              <Aside className={styles.aside}>Aside</Aside>
              <Footer className={styles.footer}>Footer</Footer>
            </Layout>
          </ViewportView>
        </div>
        <h3>(Aside-[Header-Content-Footer])</h3>
        <div className={styles.layoutContainer}>
          <ViewportView>
            <Layout className={styles.asideFirst}>
              <Aside className={styles.aside}>Aside</Aside>
              <Header className={styles.header}>Header</Header>
              <Main className={styles.main}>Main</Main>
              <Footer className={styles.footer}>Footer</Footer>
            </Layout>
          </ViewportView>
        </div>
      </section>
    </div>
  )
}

export default LayoutPage
