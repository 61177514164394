import React from 'react'
import { NumericValue } from '../redux/CSSVariables'
import FontFamilySelector from './FontFamilySelector'
import FontSizeSlider from './FontSizeSlider'
import styles from './TypographyPage.module.css'

export interface Props {
  fontFamily: string
  h1FontSize: NumericValue
  h2FontSize: NumericValue
  h3FontSize: NumericValue
  h4FontSize: NumericValue
  h5FontSize: NumericValue
  h6FontSize: NumericValue
  pFontSize: NumericValue
  onFontFamilyChange: (value: string) => void
  onH1Change: (value: NumericValue) => void
  onH2Change: (value: NumericValue) => void
  onH3Change: (value: NumericValue) => void
  onH4Change: (value: NumericValue) => void
  onH5Change: (value: NumericValue) => void
  onH6Change: (value: NumericValue) => void
  onPChange: (value: NumericValue) => void
}

function TypographyPage(props: Props) {
  const alphabet =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ\nabcdefghijklmnopqrstuvwxyz\n‘?’“!”(%)[#]{@}/&<-+÷×=>®©$€£¥¢:;,.*'

  return (
    <div>
      <h1>Typography</h1>
      <section>
        <h2>Typefaces</h2>
        <FontFamilySelector
          selected={props.fontFamily}
          onChange={props.onFontFamilyChange}
        />
        <h3>font-family: {props.fontFamily}</h3>
        <p className={styles.p}>{alphabet}</p>
        <p className={styles.pBold}>{alphabet}</p>
        <p className={styles.pLight}>{alphabet}</p>
      </section>
      <section>
        <h2>Sizes</h2>
        <ul>
          <li>
            <div>h1</div>
            <FontSizeSlider
              value={props.h1FontSize}
              onChange={props.onH1Change}
            />
            <p>
              Font Size: {props.h1FontSize.number}
              {props.h1FontSize.unit}
            </p>
            <h1>Heading One</h1>
          </li>
          <li>
            <div>h2</div>
            <FontSizeSlider
              value={props.h2FontSize}
              onChange={props.onH2Change}
            />
            <p>
              Font Size: {props.h2FontSize.number}
              {props.h2FontSize.unit}
            </p>
            <h2>Heading Two</h2>
          </li>
          <li>
            <div>h3</div>
            <FontSizeSlider
              value={props.h3FontSize}
              onChange={props.onH3Change}
            />
            <p>
              Font Size: {props.h3FontSize.number}
              {props.h3FontSize.unit}
            </p>
            <h3>Heading Three</h3>
          </li>
          <li>
            <div>h4</div>
            <FontSizeSlider
              value={props.h4FontSize}
              onChange={props.onH4Change}
            />
            <p>
              Font Size: {props.h4FontSize.number}
              {props.h4FontSize.unit}
            </p>
            <h4>Heading Four</h4>
          </li>
          <li>
            <div>h5</div>
            <FontSizeSlider
              value={props.h5FontSize}
              onChange={props.onH5Change}
            />
            <p>
              Font Size: {props.h5FontSize.number}
              {props.h5FontSize.unit}
            </p>
            <h5>Heading Five</h5>
          </li>
          <li>
            <div>h6</div>
            <FontSizeSlider
              value={props.h6FontSize}
              onChange={props.onH6Change}
            />
            <p>
              Font Size: {props.h6FontSize.number}
              {props.h6FontSize.unit}
            </p>
            <h6>Heading Six</h6>
          </li>
          <li>
            <div>p</div>
            <FontSizeSlider
              value={props.pFontSize}
              onChange={props.onPChange}
            />
            <p>
              Font Size: {props.pFontSize.number}
              {props.pFontSize.unit}
            </p>
            <p>Paragraph Text</p>
          </li>
        </ul>
      </section>
    </div>
  )
}

export default TypographyPage
