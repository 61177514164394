import * as React from 'react'
import { Route, NavLink, RouteProps, NavLinkProps } from 'react-router-dom'
import cc from 'classcat'
import HomePage from './page-home/Home'
import TypographyPage from './page-typography/TypographyPageContainer'
import ColorsPage from './page-colors/ColorsPageContainer'
import ButtonsPage from './page-buttons/ButtonsPageContainer'
import LayoutPage from './page-layout/LayoutPageContainer'
import FormsPage from './page-forms/FormsPageContainer'
import ListsPage from './page-lists/ListsPageContainer'
import NavigationPage from './page-navigation/NavigationPageContainer'
import TextInput from './components/TextInput'
import Navigation from './components/Navigation'
import OnesNavLink from './components/NavLink'
import Button from './components/Button'
import CSSVariablePainter from './utils/CSSVariablePainter'
import ViewportPainter from './utils/ViewportPainter'
import useToggle from './hooks/useToggle'
import { Layout, Header, Main, Aside, Footer } from './components/Layout'
import ViewportResizer from './utils/ViewportResizer'
import styles from './App.module.css'
import NavigationBar from './components/NavigationBar'
import ColorSchemePicker from './utils/ColorSchemePicker';

type NavRoute = Pick<NavLinkProps, 'exact' | 'strict'> & {
  path: string
  title: string
  component: RouteProps['component']
}

const navRoutes: NavRoute[] = [
  { path: '/', title: 'Home', component: HomePage, exact: true },
  { path: '/typography', title: 'Typography', component: TypographyPage },
  { path: '/colors', title: 'Colors', component: ColorsPage },
  { path: '/layout', title: 'Layout', component: LayoutPage },
  { path: '/buttons', title: 'Buttons', component: ButtonsPage },
  { path: '/form', title: 'Forms', component: FormsPage },
  { path: '/lists', title: 'Lists', component: ListsPage },
  { path: '/navigation', title: 'Navigation', component: NavigationPage },
]

function App() {
  const [navIsOpen, toggleNav] = useToggle()

  const navLinks: React.ReactNodeArray = []
  const routes: React.ReactNodeArray = []
  navRoutes.forEach(navRoute => {
    navLinks.push(
      <OnesNavLink
        key={navRoute.path}
        element={NavLink}
        to={navRoute.path}
        exact={navRoute.exact}
      >
        {navRoute.title}
      </OnesNavLink>,
    )
    routes.push(
      <Route
        key={navRoute.path}
        path={navRoute.path}
        component={navRoute.component}
        exact={navRoute.exact}
      />,
    )
  })

  return (
    <Layout>
      <Header className={styles.header}>
        <NavigationBar aria-label="actions">
          <span className={styles.title}>Style Guide</span>
          <div className={styles.menu}>
            <Button onClick={toggleNav}>Menu</Button>
          </div>
          <ViewportResizer />
          <ColorSchemePicker />
        </NavigationBar>
      </Header>
      <Aside className={styles.aside}>
        <div className={cc([styles.nav, { [styles.navOpen]: navIsOpen }])}>
          {navIsOpen && <Button onClick={toggleNav}>Menu</Button>}
          <TextInput placeholder="Search" />
          <Navigation role="navigation" direction="col">
            {navLinks}
          </Navigation>
        </div>
      </Aside>
      <Main className={styles.main}>{routes}</Main>
      <Footer className={styles.footer}>Footer</Footer>
      <CSSVariablePainter />
      <ViewportPainter />
    </Layout>
  )
}

export default App
