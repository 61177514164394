import React from 'react'
import TextInput from '../components/TextInput'
import NativeSelect from '../components/NativeSelect'
import NativeColorPicker from '../components/NativeColorPicker'
import Slider from '../components/Slider'
import ViewportView from '../utils/ViewportView'

export interface Props {}

function FormsPage(props: Props) {
  return (
    <div>
      <h1>Forms</h1>
      <section>
        <h2>Inputs</h2>
        <ul>
          <li>
            <div>Slider</div>
            <ViewportView>
              <Slider />
            </ViewportView>
          </li>
          <li>
            <div>TextInput</div>
            <ViewportView>
              <TextInput />
            </ViewportView>
          </li>
          <li>
            <div>Select</div>
            <ViewportView>
              <NativeSelect>
                <option value="" />
                <option value={1}>Option 1</option>
                <option value={2}>Option 2</option>
                <option value={3}>Option 3</option>
                <option value={4}>Option 4</option>
              </NativeSelect>
            </ViewportView>
          </li>
          <li>
            <div>Color Picker</div>
            <ViewportView>
              <NativeColorPicker />
            </ViewportView>
          </li>
        </ul>
      </section>
    </div>
  )
}

export default FormsPage
