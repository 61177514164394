import { HSL } from '@ctrl/tinycolor/interfaces'
import { TinyColor } from '@ctrl/tinycolor'
import { NumericValue } from '../redux/CSSVariables'

export function isColorInput(color: any): color is HSL {
  return new TinyColor(color).isValid
}
export function isNumericValue(value: any): value is NumericValue {
  return 'number' in value && 'unit' in value
}
