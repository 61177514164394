import { useState, useEffect } from 'react'
import axios from 'axios'

export type ComponentString = string
export type FileCache = {
  [url: string]: ComponentString
}

// Cache files using memory
const cachedFiles: FileCache = {}

export default function useFile(fileUrl: string): ComponentString {
  const [files, setFiles] = useState(cachedFiles)
  useEffect(() => {
    if (files[fileUrl]) {
      return
    }
    const source = axios.CancelToken.source()
    axios
      .get(fileUrl, { cancelToken: source.token })
      .then(res => {
        cachedFiles[fileUrl] = res.data
        setFiles(cachedFiles)
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          console.error(error)
        }
      })

    // Cancel request on unmount
    return () => source.cancel()
  }, [])

  return files[fileUrl] || ''
}
