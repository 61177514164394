import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { TinyColor } from '@ctrl/tinycolor'
import { ReduxState } from '../redux/store'
import {
  StateKeys as CSSVariablesKeys,
  State as CSSVariablesState,
} from '../redux/CSSVariables'
import { isString } from 'util'
import { keys } from './keys'
import { isNumericValue, isColorInput } from './typeguards'

const PropToCSSVar: Record<CSSVariablesKeys, string> = {
  fontFamily: '--font-family',
  color: '--color',
  backgroundColor: '--background-color',
  h1FontSize: '--h1-font-size',
  h2FontSize: '--h2-font-size',
  h3FontSize: '--h3-font-size',
  h4FontSize: '--h4-font-size',
  h5FontSize: '--h5-font-size',
  h6FontSize: '--h6-font-size',
  pFontSize: '--p-font-size',

  gray100: '--gray-100',
  gray200: '--gray-200',
  gray300: '--gray-300',
  gray400: '--gray-400',
  gray500: '--gray-500',
  gray600: '--gray-600',
  gray700: '--gray-700',
  gray800: '--gray-800',
  gray900: '--gray-900',

  blue100: '--blue-100',
  blue200: '--blue-200',
  blue300: '--blue-300',
  blue400: '--blue-400',
  blue500: '--blue-500',
  blue600: '--blue-600',
  blue700: '--blue-700',
  blue800: '--blue-800',
  blue900: '--blue-900',
}

function CSSVariablePainter(props: CSSVariablesState) {
  useEffect(
    () => {
      keys(props).forEach(key => {
        const variable = PropToCSSVar[key]
        const value = props[key]
        if (!variable) return

        let propertyValue: string
        if (isString(value)) {
          propertyValue = value
        } else if (isNumericValue(value)) {
          propertyValue = `${value.number}${value.unit}`
        } else if (isColorInput(value)) {
          propertyValue = new TinyColor(value).toHslString()
        } else {
          throw new Error(
            `Unknown type encounter: ${key}:${JSON.stringify(value, null, 2)}`,
          )
        }
        document.documentElement.style.setProperty(variable, propertyValue)
      })
    },
    [props],
  )

  return null
}

const mapStateToProps = (state: ReduxState) => ({ ...state.CSSVariables })

export default connect(mapStateToProps)(CSSVariablePainter)
