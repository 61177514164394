import React from 'react'
import styles from './TextInput.module.css'

interface Props {
  id?: string
  value?: string | number | string[]
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
  multiple?: boolean
  children: React.ReactNode
}

function Select(props: Props, ref: React.Ref<HTMLSelectElement>) {
  return (
    <select
      ref={ref}
      className={styles.input}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      form={props.id}
      disabled={props.disabled}
      multiple={props.multiple}
    >
      {props.children}
    </select>
  )
}

export default React.forwardRef(Select)
