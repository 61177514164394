import React from 'react'
import Button from '../components/Button'
import ButtonGroup from '../components/ButtonGroup'
import styles from './ButtonsPage.module.css'

export interface Props {}

function ButtonsPage(props: Props) {
  return (
    <>
      <h1>ButtonsPage</h1>
      <section>
        <h2>Buttons</h2>
        <Button variant="primary">primary</Button>&nbsp;
        <Button variant="default">primary</Button>&nbsp;
        <h3>Types</h3>
        <Button type="button">button</Button>&nbsp;
        <Button type="submit">submit</Button>&nbsp;
        <Button type="reset">reset</Button>&nbsp;
        <h3>States</h3>
        <h4>Default</h4>
        <Button disabled>disabled</Button>&nbsp;
        <Button state="focus">focus</Button>&nbsp;
        <Button state="hover">hover</Button>
        <Button state="active">active</Button>&nbsp;
        <h4>Primary</h4>
        <Button variant="primary" disabled>
          disabled
        </Button>
        &nbsp;
        <Button variant="primary" state="focus">
          focus
        </Button>
        &nbsp;
        <Button variant="primary" state="hover">
          hover
        </Button>
        &nbsp;
        <Button variant="primary" state="active">
          active
        </Button>
        &nbsp;
      </section>
      <section>
        <h2>Button Group</h2>
        <ButtonGroup aria-label="Demo">
          <Button>button</Button>
          <Button>submit</Button>
          <Button>reset</Button>
        </ButtonGroup>
      </section>
    </>
  )
}

export default ButtonsPage
