import React from 'react'
import { State } from '../redux/CSSVariables'
import styles from './ColorsPage.module.css'
import Swatch, { Props as SwatchProps } from './Swatch'
import NativeList from '../components/NativeList'
import { checkPropTypes } from 'prop-types'

export type Props = Pick<
  State,
  | 'backgroundColor'
  | 'color'
  | 'gray100'
  | 'gray200'
  | 'gray300'
  | 'gray400'
  | 'gray500'
  | 'gray600'
  | 'gray700'
  | 'gray800'
  | 'gray900'
  | 'blue100'
  | 'blue200'
  | 'blue300'
  | 'blue400'
  | 'blue500'
  | 'blue600'
  | 'blue700'
  | 'blue800'
  | 'blue900'
>

function ListItem(props: SwatchProps) {
  return (
    <li className={styles.listItem}>
      <Swatch color={props.color} name={props.name} />
    </li>
  )
}

function ColorsPage(props: Props) {
  return (
    <div>
      <h1>ColorsPage</h1>
      <section>
        <h2>UI</h2>
        <Swatch color={props.backgroundColor} name="color" />
        <Swatch color={props.color} name="background-color" />
      </section>
      <section>
        <h2>Brand</h2>
      </section>
      <section>
        <h2>Grays</h2>
        <ol className={styles.list}>
          <ListItem color={props.gray100} name="gray-100" />
          <ListItem color={props.gray200} name="gray-200" />
          <ListItem color={props.gray300} name="gray-300" />
          <ListItem color={props.gray400} name="gray-400" />
          <ListItem color={props.gray500} name="gray-500" />
          <ListItem color={props.gray600} name="gray-600" />
          <ListItem color={props.gray700} name="gray-700" />
          <ListItem color={props.gray800} name="gray-800" />
          <ListItem color={props.gray900} name="gray-900" />
        </ol>
      </section>
      <section>
        <h2>Blues</h2>
        <ol className={styles.list}>
          <ListItem color={props.blue100} name="blue-100" />
          <ListItem color={props.blue200} name="blue-200" />
          <ListItem color={props.blue300} name="blue-300" />
          <ListItem color={props.blue400} name="blue-400" />
          <ListItem color={props.blue500} name="blue-500" />
          <ListItem color={props.blue600} name="blue-600" />
          <ListItem color={props.blue700} name="blue-700" />
          <ListItem color={props.blue800} name="blue-800" />
          <ListItem color={props.blue900} name="blue-900" />
        </ol>
      </section>
    </div>
  )
}

export default ColorsPage
