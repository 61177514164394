import React from 'react'
import styles from './NavigationBar.module.css'

export interface Props {
  'aria-label'?: string
  'aria-labelledby'?: string
  role?: 'navigation'
  children: React.ReactNode
}

/**
 * Navigation bar that enforces semantics when possible
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/nav
 * @param props
 */
function NavigationBar(props: Props) {
  return <div className={styles.navbar}>{props.children}</div>
}

export default NavigationBar
