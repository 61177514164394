import React from 'react'
import styles from './TextInput.module.css'

interface Props {
  id?: string
  value?: string | number | string[]
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  multiple?: boolean
}

function NativeColorPicker(props: Props, ref: React.Ref<HTMLInputElement>) {
  return (
    <input
      ref={ref}
      id={props.id}
      className={styles.input}
      type="color"
      value={props.value}
      onChange={props.onChange}
      form={props.id}
      disabled={props.disabled}
      multiple={props.multiple}
    />
  )
}

export default React.forwardRef(NativeColorPicker)
