import React from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../redux/store'
import { Actions } from '../redux/Viewport'
import Button from '../components/Button'

const mapStateToProps = (state: ReduxState) => ({
  colorScheme: state.Viewport.colorScheme,
})

const mapDispatchToProps = {
  setColorScheme: Actions.setColorScheme,
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
function ViewportResizer(props: Props) {
  const isDark = props.colorScheme === 'dark'
  return (
    <Button onClick={() => props.setColorScheme(isDark ? 'light' : 'dark')}>
      {isDark ? 'Light' : 'Dark'}
    </Button>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewportResizer)
