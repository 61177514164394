import React from 'react'
import './NavLink.css'

export interface Props {
  element: React.ComponentType<any>
  className?: string
  style?: React.CSSProperties
  [x: string]: any
}

/**
 * Wrapper component that applies `className` and `activeClassName`
 * to prop `element`.
 * NavLink's `className` prop applies to li element instead.
 * @param props
 */
function NavLink(props: Props) {
  const {
    className,
    style,
    activeClassName,
    activeStyle,
    element: NavigationLink,
    ...restOfProps
  } = props
  return (
    <li className={className} style={style}>
      <NavigationLink
        {...restOfProps}
        className="nav-link"
        activeClassName="nav-link-active"
      />
    </li>
  )
}

export default NavLink
