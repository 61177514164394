import React, { useCallback } from 'react'
import useGoogleFonts, { Font } from '../hooks/useGoogleFonts'
import NativeSelect from '../components/NativeSelect'

export interface Props {
  selected: string
  onChange: (value: string) => void
}

function getFontLink(font: Font): HTMLLinkElement {
  const link = document.createElement('link')
  let apiUrl = 'https://fonts.googleapis.com/css?family='
  apiUrl += font.family.replace(/ /g, '+')
  const variants = font.variants.filter(variant =>
    ['regular', 'italic', '300', '700'].includes(variant),
  )
  if (variants.length) {
    apiUrl += `:${variants.join(',')}`
  }
  link.href = apiUrl
  link.rel = 'stylesheet'
  return link
}

function FontFamilySelector(props: Props) {
  const fonts = useGoogleFonts()

  const onChange = useCallback(
    e => {
      const index = e.target.value
      const selectedFont = fonts[index]
      if (!selectedFont) {
        props.onChange(index)
        return
      }

      // Get a reference to the current in-use stylesheet, if there is one.
      const styleId = 'font-style-sheet'
      const fontStyleSheet = document.getElementById(styleId)

      const link = getFontLink(selectedFont)
      link.id = styleId
      // Avoid flash of unstyled text by removing old on swap
      link.onload = () => {
        props.onChange(selectedFont.family)
        if (fontStyleSheet && fontStyleSheet.parentElement) {
          fontStyleSheet.parentElement.removeChild(fontStyleSheet)
        }
      }
      link.onerror = ev => {
        console.error(ev)
      }
      document.head.appendChild(link)
    },
    [fonts],
  )

  return (
    <NativeSelect onChange={onChange}>
      <option
        value="System Stack"
        selected={props.selected === 'System Stack'}
      >
        System Stack
      </option>
      {fonts.map((font, i) => (
        <option
          key={font.family}
          value={i}
          selected={props.selected === font.family}
        >
          {font.family}
        </option>
      ))}
    </NativeSelect>
  )
}

export default FontFamilySelector
